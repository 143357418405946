<template>
  <div class="loading" ref="root">
    <p class="loading__text">
      <span>{{ years }}</span> Jahre
    </p>
    <div class="loading__quote">
      <p class="loading__quote-title" ref="title">NRW Jubiläum Karte</p>
      <p class="loading__quote-subtitle" ref="subtitle">Eine digitale Feier</p>
    </div>
    <div class="loading__overlay" ref="overlay"></div>
  </div>
</template>

<script>
import { gsap, SteppedEase } from 'gsap'
import { ref, watch } from 'vue'
export default {
  props: {
    loaded: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ELEMENTS
    const root = ref(null)
    const overlay = ref(null)
    const title = ref(null)
    const subtitle = ref(null)

    const years = ref(0)
    const initValue = Math.floor(Math.random() * 33)

    document.fonts.onloadingdone = function (fontFaceSetEvent) {
      // console.log('fonts: ' + fontFaceSetEvent.fontfaces)
      gsap.to(overlay.value, {
        autoAlpha: 0,
        duration: 0.3
      })
    }

    const init = () => {
      gsap.to(years, {
        value: initValue,
        ease: SteppedEase.config(initValue),
        duration: 0.5,
        onComplete: () => {
          finish()
        }
      })
    }

    const finish = () => {
      gsap.to(years, {
        value: 75,
        ease: SteppedEase.config(75 - initValue),
        duration: 0.5,
        delay: 0.2,
        onComplete: () => {
          destroy()
        }
      })
    }

    const destroy = () => {
      const tl = gsap.timeline({ paused: true })
      tl.to(title.value, {
        y: 0,
        opacity: 1,
        duration: 1
      })
      tl.to(subtitle.value, {
        y: 0,
        opacity: 1,
        duration: 1
      })
      tl.to(root.value, {
        autoAlpha: 0,
        duration: 2,
        delay: 1,
        onComplete: () => {
          root.value.style.display = 'none'
        }
      })
      tl.play()
    }

    //* WATCHES
    watch(
      () => props.loaded,
      (first, second) => {
        init()
      }
    )

    return {
      root,
      overlay,
      title,
      subtitle,
      years,
      init,
      finish,
      destroy
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $secundary;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading__text {
    font-family: $lora;
    font-size: 52px;
    font-weight: 600;

    span {
      color: $primary;
    }
  }

  .loading__quote {
    position: absolute;
    bottom: 30px;
    text-align: center;

    .loading__quote-title {
      font-family: $lora;
      font-size: 23px;
      font-weight: 500;
      margin: 0;
      opacity: 0;
      @include transform(translateY(100%));
    }

    .loading__quote-subtitle {
      font-family: $fira;
      font-size: 17px;
      font-weight: 400;
      margin: 0;
      opacity: 0;
      @include transform(translateY(100%));
    }
  }

  .loading__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
  }
}
</style>
