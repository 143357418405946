<template>
  <div class="video" ref="root">
    <div class="video__wrapper videoAnim">
      <iframe
        :src="url"
        style="top: 0; left: 0; width: 100%; height: 100%"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
      ></iframe>
    </div>
    <div class="video__title">
      <h2 class="itemAnim">{{ title }}</h2>
      <p class="itemAnim">{{ subtitle }}</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import gsap from 'gsap'
export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'UNESCO-Welterbe Zollverein'
    },
    subtitle: {
      type: String,
      default: 'Essen, 1995'
    }
  },
  emits: ['loaded'],
  setup (props, context) {
    const root = ref(null)
    let items
    let video
    onMounted(() => {
      items = root.value.querySelectorAll('.itemAnim')
      video = root.value.querySelector('.videoAnim')
      gsap.set(video, {
        opacity: 0
      })
      items.forEach((element) => {
        gsap.set(element, {
          opacity: 0
        })
      })
      items.forEach((element, index) => {
        gsap.to(element, {
          opacity: 1,
          duration: 0.5,
          delay: 1.5 + index / 5,
          onComplete: () => {
            showVideo()
          }
        })
      })
    })

    const showVideo = () => {
      context.emit('loaded')
      gsap.to(video, {
        opacity: 1,
        duration: 0.5
      })
    }
    return {
      root,
      showVideo
    }
  }
}
</script>

<style lang="scss" scoped>
.video {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: [col-start] 110px [col-1] calc(100vw - 220px) [col-margin] 110px [col-end];
  grid-template-rows: [row-start] 110px [row-1] calc(100vh - 160px) [row-2] 50px [row-end];
  grid-template-areas:
    ". title ."
    ". video ."
    ". . .";

  .video__wrapper {
    grid-area: video;
  }

  .video__title {
    grid-area: title;
    place-self: start;
    width: fit-content;
    height: fit-content;
    margin-top: 30px;

    h2 {
      font-family: $lora;
      font-size: 17px;
      margin: 0;
      color: $primary;
    }

    p {
      font-family: $fira;
      font-size: 15px;
      margin: 0;
    }
  }

  @include breakpoint(sm) {
    grid-template-columns: [col-start] 30px [col-1] calc(100% - 60px) [col-margin] 30px [col-end];
    grid-template-rows: [row-start] 150px [row-1] calc(100% - 210px) [row-2] 50px [row-end];

    .video__title {
      place-self: end center;

      p {
        text-align: center;
      }
    }
  }

  @include breakpoint(o-h) {
    grid-template-columns: [col-start] 30px [col-1] calc(100% - 60px) [col-margin] 30px [col-end];
    grid-template-rows: [row-start] 30px [row-1] calc(100% - 60px) [row-2] 30px [row-end];

    .video__title {
      display: none;
    }
  }
}
</style>
