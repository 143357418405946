<template>
  <Map @loaded="loaded"/>
  <Menu/>
  <Loading :loaded="isLoad"/>
</template>

<script>
import Loading from './components/Loading'
import Map from './components/Map'
import Menu from './components/Menu'

import { ref } from 'vue'

export default {
  name: 'App',
  components: {
    Loading,
    Map,
    Menu
  },
  setup () {
    const isLoad = ref(false)
    const loaded = () => {
      // console.log('loaded')
      isLoad.value = true
    }
    return {
      isLoad,
      loaded
    }
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  color: $dark;
  background-color: $secundary;
}
</style>
