<template>
  <div class="galery" ref="wrapper">
    <div class="galery__wrapper" ref="container">
      <div class="galery__title">
        <h2 class="itemAnim">{{ title }}</h2>
        <p class="itemAnim">{{ subtitle }}</p>
      </div>
      <div class="galery__photos" ref="galery">
        <template v-if="content.template === 0">
          <div class="galery__img-wrapper collage__four">
            <div class="collage__four-up">
              <img
                class="galery__img"
                src="https://res.cloudinary.com/loretolala/image/upload/v1629595396/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein_dlce7e.jpg"
                alt=""
              />
            </div>
            <div class="collage__four-down">
              <img
                class="galery__img"
                src="https://res.cloudinary.com/loretolala/image/upload/v1629595396/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-2_tyjrht.jpg"
                alt=""
              />
              <img
                class="galery__img"
                src="https://res.cloudinary.com/loretolala/image/upload/v1629595397/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-3_ymwtmb.jpg"
                alt=""
              />
              <img
                class="galery__img"
                src="https://res.cloudinary.com/loretolala/image/upload/v1629595401/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-4_rdyxbg.png"
                alt=""
              />
            </div>
          </div>
          <div class="galery__img-wrapper collage__three">
            <div class="collage__three-up">
              <img
                class="galery__img"
                src="https://res.cloudinary.com/loretolala/image/upload/v1629595396/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-5_lsffei.jpg"
                alt=""
              />
              <img
                class="galery__img"
                src="https://res.cloudinary.com/loretolala/image/upload/v1629595397/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-7_rmz7fa.jpg"
                alt=""
              />
            </div>
            <div class="collage__three-down">
              <img
                class="galery__img"
                src="https://res.cloudinary.com/loretolala/image/upload/v1629595396/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-6_h0ifsz.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="galery__img-wrapper collage__iform inverted">
            <img
              class="galery__img"
              src="https://res.cloudinary.com/loretolala/image/upload/v1629595396/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-8_ix7dyq.jpg"
              alt=""
            />
            <img
              class="galery__img"
              src="https://res.cloudinary.com/loretolala/image/upload/v1629595397/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-9_l7c5ym.jpg"
              alt=""
            />
          </div>
        </template>
        <template v-if="content.template === 1">
          <div class="galery__img-wrapper">
            <img
              class="galery__img"
              src="https://res.cloudinary.com/loretolala/image/upload/v1629598340/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-1_yfgoez.jpg"
              alt=""
            />
          </div>
          <div class="galery__img-wrapper collage__iform">
            <img
              class="galery__img"
              src="https://res.cloudinary.com/loretolala/image/upload/v1629598340/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-2_ybtfja.jpg"
              alt=""
            />
            <img
              class="galery__img"
              src="https://res.cloudinary.com/loretolala/image/upload/v1629598331/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-3_ttvtb1.jpg"
              alt=""
            />
          </div>
          <div class="galery__img-wrapper collage__two">
            <img
              class="galery__img"
              src="https://res.cloudinary.com/loretolala/image/upload/v1629598331/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-4_yh8coi.jpg"
              alt=""
            />
            <img
              class="galery__img"
              src="https://res.cloudinary.com/loretolala/image/upload/v1629598334/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-5_uyj2bx.jpg"
              alt=""
            />
          </div>
          <div class="galery__img-wrapper collage__two">
            <img
              class="galery__img"
              src="https://res.cloudinary.com/loretolala/image/upload/v1629598332/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-6_jqk1kk.jpg"
              alt=""
            />
            <img
              class="galery__img"
              src="https://res.cloudinary.com/loretolala/image/upload/v1629598332/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-7_q4iedo.jpg"
              alt=""
            />
          </div>
        </template>
        <div class="galery__img-wrapper" v-for="img in content.imgs" :key="img">
          <img class="galery__img" :src="img" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div class="galery__btns">
    <div class="galery__btn" @click="scrollTo(false)">
      <img
        class="galery__btn-icon"
        src="https://res.cloudinary.com/nancloud/image/upload/v1624896673/NRW/WEB/ICONS/PREVIOUS_p7i2rm.svg"
        alt="previus"
        width="20"
      />
    </div>
    <div class="galery__btn" @click="scrollTo(true)">
      <img
        class="galery__btn-icon"
        src="https://res.cloudinary.com/nancloud/image/upload/v1623896673/NRW/WEB/ICONS/NEXT_zzswgd.svg"
        alt="next"
        width="20"
      />
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Draggable } from 'gsap/Draggable'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import imagesLoaded from 'imagesloaded'

import { onMounted, ref } from 'vue'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(Draggable)
gsap.registerPlugin(ScrollToPlugin)

export default {
  props: {
    content: {
      type: Array
    },
    title: {
      type: String,
      default: 'UNESCO-Welterbe Zollverein'
    },
    subtitle: {
      type: String,
      default: 'Essen, 1995'
    }
  },
  emits: ['loaded'],
  setup (props, context) {
    const wrapper = ref(null)
    const container = ref(null)
    const galery = ref(null)
    const imgs = ref([
      'https://res.cloudinary.com/nancloud/image/upload/v1627616523/NRW/WEB/GALLERIES/ZOLLVEREIN/07_afkoxc.webp',
      'https://res.cloudinary.com/nancloud/image/upload/v1627616524/NRW/WEB/GALLERIES/ZOLLVEREIN/Zollverein-08_jeeytj.jpg',
      'https://res.cloudinary.com/nancloud/image/upload/v1627616524/NRW/WEB/GALLERIES/ZOLLVEREIN/Zollverein-09_ppdmoc.jpg',
      'https://res.cloudinary.com/nancloud/image/upload/v1627616524/NRW/WEB/GALLERIES/ZOLLVEREIN/Zollverein-10_manghx.jpg'
    ])
    let scroll, sections
    // let proxy, drag
    let maxWidth, items, images

    const getMaxWidth = () => {
      sections = gsap.utils.toArray('.galery__img-wrapper')
      maxWidth = 0
      sections.forEach((section) => {
        maxWidth += section.offsetWidth
      })
      maxWidth -= window.innerWidth - 200
    }

    // const updateProxy = () => {
    //   // move the handler to the corresponding ratio according to the page's scroll position.
    //   if (scroll) {
    //     gsap.set(proxy, { x: -scroll.scroll() })
    //   }
    // }

    const initScroll = () => {
      getMaxWidth()
      ScrollTrigger.addEventListener('refreshInit', getMaxWidth)

      const scrollTween = gsap.to(sections, {
        x: () => `-${maxWidth}`,
        ease: 'none'
      })

      scroll = ScrollTrigger.create({
        id: 'scroll',
        animation: scrollTween,
        scroller: wrapper.value,
        trigger: container.value,
        end: () => `+=${maxWidth}`,
        scrub: props.content.template === 0 ? 0.5 : 2,
        pin: true,
        invalidateOnRefresh: true
        // onUpdate: updateProxy
      })
      console.log(scroll)

      // proxy = document.createElement('div')
      // drag = Draggable.create(proxy, {
      //   trigger: container.value,
      //   type: 'x',
      //   lockAxis: true,
      //   inertia: false,
      //   throwProps: false,
      //   bounds: {
      //     maxX: 0
      //   },
      //   onPress () {
      //     this.startScroll = scroll.scroll()
      //   },
      //   onThrowUpdate: function () {
      //     // scroll.scroll(-this.x)
      //     scroll.scroll(this.startScroll - (this.x - this.startX))
      //   },
      //   onDrag: function () {
      //     // scroll.scroll(-this.x)
      //     scroll.scroll(this.startScroll - (this.x - this.startX))
      //   }
      // })[0]
      // console.log(drag)
    }

    const scrollTo = (front) => {
      // gsap.to(wrapper.value, {
      //   duration: 1,
      //   scrollTo: { y: distance }
      // })
      // scroll.scroll(scroll.scroll() + distance)
      let distance = window.innerWidth / 2
      !front ? (distance *= -1) : (distance *= 1)
      const actualScroll = scroll.scroll()
      const goto = actualScroll + distance
      scroll.scroll(goto)
    }

    onMounted(() => {
      items = wrapper.value.querySelectorAll('.itemAnim')
      images = wrapper.value.querySelectorAll('.galery__img')
      // console.log('content: ', props)

      items.forEach((element) => {
        gsap.set(element, {
          opacity: 0
        })
      })

      images.forEach((element) => {
        gsap.set(element, {
          opacity: 0
        })
      })

      imagesLoaded(galery.value, function (instance) {
        items.forEach((element, index) => {
          gsap.to(element, {
            opacity: 1,
            duration: 0.5,
            delay: 1.5 + index / 5,
            onComplete: () => {
              showImages()
            }
          })
        })
      })
    })

    const showImages = () => {
      if (window.innerWidth > 1024) {
        if (props.content.template === 0) {
          const collageFour = galery.value.querySelector('.collage__four')
          const collageFourUp = galery.value
            .querySelector('.collage__four-up')
            .getBoundingClientRect()
          collageFour.style.width = collageFourUp.width + 'px'

          const collageThree = galery.value.querySelector('.collage__three')
          const collageThreeDown = galery.value
            .querySelector('.collage__three-down')
            .getBoundingClientRect()
          collageThree.style.width = collageThreeDown.width + 'px'
        }
        initScroll()
      } else {
        const col3 = galery.value.querySelectorAll('.galery__img-wrapper')
        col3.forEach((elem) => {
          elem.className = ''
          elem.classList.add('galery__img-wrapper')
        })
      }

      const tl = gsap.timeline({
        paused: true
      })
      images.forEach((element, index) => {
        tl.to(
          element,
          {
            opacity: 1,
            duration: 0.5
          },
          '<0.2'
        )
      })
      tl.play()
      context.emit('loaded')
    }

    return {
      wrapper,
      container,
      galery,
      imgs,
      initScroll,
      scrollTo,
      showImages
    }
  }
}
</script>

<style lang="scss" scoped>
.galery {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  height: 100%;
  width: 100%;
  overflow: hidden;

  .galery__wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: [col-start] 110px [col-1] 1fr [col-margin] 110px [col-end];
    grid-template-rows: [row-start] 110px [row-2] calc(100% - 160px) [row-3] 50px [row-end];
    grid-template-areas:
      ". title ."
      ". galery ."
      ". . .";

    .galery__title {
      grid-area: title;
      place-self: start;
      width: fit-content;
      height: fit-content;
      margin-top: 30px;

      h2 {
        font-family: $lora;
        font-size: 17px;
        margin: 0;
        color: $primary;
      }

      p {
        font-family: $fira;
        font-size: 15px;
        margin: 0;
      }

      .galery__title-line {
        margin-top: 1rem;
        width: 100%;
        height: 1px;
        background-color: $dark;
      }

      @include breakpoint(sm) {
        place-self: start center;

        p {
          text-align: center;
        }
      }
    }

    .galery__photos {
      display: flex;
      flex-flow: row nowrap;
      grid-area: galery;

      .galery__img-wrapper {
        height: 100%;
        .galery__img {
          height: 100%;
          margin-right: 2rem;
        }

        &.collage__three {
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
          margin-right: 2rem;

          .collage__three-up {
            height: 38%;
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
          }

          .collage__three-down {
            height: 58%;
            width: fit-content;
            overflow: hidden;
          }

          .galery__img {
            margin-right: 0;
          }
        }

        &.collage__four {
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
          margin-right: 2rem;

          .collage__four-up {
            height: 58%;
            width: fit-content;
            overflow: hidden;
          }

          .collage__four-down {
            height: 40%;
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
          }

          .galery__img {
            margin-right: 0;
          }
        }

        &.collage__two {
          // width: 27vw;
          margin-right: 2rem;

          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;

          .galery__img {
            height: 48%;
            margin-right: 0;
          }
        }

        &.collage__iform {
          // width: 27vw;
          // margin-right: 2rem;

          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;

          .galery__img {
            &:first-child {
              height: 33%;
              width: auto;
            }

            &:last-child {
              height: 62%;
              width: auto;
            }
          }

          &.inverted {
            .galery__img {
              &:first-child {
                height: 75%;
                width: auto;
              }

              &:last-child {
                height: 22%;
                width: auto;
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(m) {
    overflow-y: auto;

    .galery__wrapper {
      height: auto;
      grid-template-columns: [col-start] 30px [col-1] calc(100vw - 60px) [col-margin] 30px [col-end];
      grid-template-rows:
        [row-start] 77px [row-2] 110px [row-3] calc(100% - 270px)
        [row-4] 50px [row-end];
      grid-template-areas:
        ". . ."
        ". title ."
        ". galery ."
        ". . .";

      .galery__photos {
        flex-flow: column nowrap;

        .galery__img-wrapper {
          height: auto;
          margin: 0;

          .galery__img {
            height: auto;
            width: 100%;
            margin: 0;
            margin-bottom: 2rem;
          }

          &.collage__two {
            margin: 0;
            // margin-top: 2rem;
          }
        }
      }
    }
  }
}

.galery__btns {
  position: absolute;
  top: 30px;
  right: 85px;

  display: flex;

  .galery__btn {
    border: 1px solid $dark;
    width: 46px;
    height: 46px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    .galery__btn-icon {
      opacity: 0.8;
      @include transform(scale(0.8));
      @include transition(all 0.3s);
    }

    &:first-child {
      margin-right: 7px;
    }

    &:hover {
      .galery__btn-icon {
        @include transform(scale(1.2));
      }
    }
  }

  @include breakpoint(m) {
    display: none;
  }
}
</style>
