<template>
  <div class="label" ref="label">
    <div class="label__lines">
      <div class="label__line-h" ref="lineH"></div>
      <div class="label__line-v" ref="lineV"></div>
    </div>
    <div class="label__content">
      <div class="label__icon" ref="labelIcon">
        <img :src="iconData" alt="icon place" />
      </div>
      <div class="label__text">
        <div class="label__text-overlay" ref="textOverlay"></div>
        <h2 class="label__title" ref="title">{{ titleData }}</h2>
        <h3 class="label__subtitle" ref="subtitle">{{ subtitleData }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import { onMounted, ref, watch } from 'vue'
export default {
  props: {
    x: Number,
    y: Number
  },
  setup (props) {
    //* ELEMENTS
    const label = ref(null)
    const labelIcon = ref(null)
    const lineV = ref(null)
    const lineH = ref(null)
    const textOverlay = ref(null)
    const title = ref(null)
    const subtitle = ref(null)

    //* VARIABLES
    const titleData = ref('')
    const subtitleData = ref('')
    const iconData = ref('')
    const tl = gsap.timeline({ paused: true })

    //* METHODS
    const initAnim = (set) => {
      document.body.style.cursor = 'pointer'
      switch (set) {
        case 'ZOLLVEREIN':
          titleData.value = 'ZECHE ZOLLVEREIN'
          subtitleData.value = 'ESSEN'
          iconData.value =
            'https://res.cloudinary.com/nancloud/image/upload/v1626803060/NRW/WEB/ICONS/PLACES/01_ZOLLVEREIN_j5yjaj.svg'
          break
        case 'JAHRHUNDDERTHALLE':
          titleData.value = 'JAHRHUNDERTHALLE'
          subtitleData.value = 'BOCHUM'
          iconData.value =
            'https://res.cloudinary.com/nancloud/image/upload/v1626803060/NRW/WEB/ICONS/PLACES/02_JAHRHUNDERT_onkclv.svg'
          break
        case 'HANSA':
          titleData.value = 'KOKEREI HANSA'
          subtitleData.value = 'DORTMUND'
          iconData.value =
            'https://res.cloudinary.com/nancloud/image/upload/v1627518174/NRW/WEB/ICONS/PLACES/03_HANSA_acfuuj.svg'
          break
        case 'LANDSHAFTPARK':
          titleData.value = 'LANDSCHAFTSPARK'
          subtitleData.value = 'DUISBURG'
          iconData.value =
            'https://res.cloudinary.com/nancloud/image/upload/v1626803060/NRW/WEB/ICONS/PLACES/04_LANDSCHAFT_rlg61r.svg'
          break
        case 'INDUSTRIEMUSEUM':
          titleData.value = 'INDUSTRIEMUSEUM'
          subtitleData.value = 'SOLINGEN'
          iconData.value =
            'https://res.cloudinary.com/nancloud/image/upload/v1627518174/NRW/WEB/ICONS/PLACES/06_INDUSTRIENMUSEUM_jgs1yw.svg'
          break
        case 'EXTER':
          titleData.value = 'EXTER & BEGATALBAHN'
          subtitleData.value = 'BARNTRUP'
          iconData.value =
            'https://res.cloudinary.com/nancloud/image/upload/v1626803060/NRW/WEB/ICONS/PLACES/07_EXETER_pvn4pz.svg'
          break
        case 'ZECHE':
          titleData.value = 'ZECHE CONSOLIDATION'
          subtitleData.value = 'GELSENKIRCHEN'
          iconData.value =
            'https://res.cloudinary.com/nancloud/image/upload/v1626803062/NRW/WEB/ICONS/PLACES/05_ZECHE_ouzjow.svg'
          break

        default:
          break
      }
      gsap.to(lineV.value, {
        height: '75px',
        duration: 0.5
      })
      gsap.to(lineH.value, {
        width: '75px',
        duration: 0.5
      })
      gsap.to(labelIcon.value, {
        width: '60px',
        duration: 0.5
      })
      gsap.to(textOverlay.value, {
        width: '100%',
        duration: 1,
        delay: 0.5
      })
      gsap.to(title.value, {
        x: '0',
        duration: 0.5,
        delay: 0.7
      })
      gsap.to(subtitle.value, {
        x: '0',
        duration: 0.5,
        delay: 0.75
      })
    }
    const deleteAnim = () => {
      document.body.style.cursor = 'default'
      gsap.killTweensOf(lineV.value)
      gsap.killTweensOf(lineH.value)
      gsap.killTweensOf(labelIcon.value)
      gsap.killTweensOf(textOverlay.value)
      gsap.killTweensOf(title.value)
      gsap.killTweensOf(subtitle.value)
      gsap.to(lineV.value, {
        height: '0',
        duration: 0.1
      })
      gsap.to(lineH.value, {
        width: '0',
        duration: 0.1
      })
      gsap.to(labelIcon.value, {
        width: '0',
        duration: 0.1
      })
      gsap.to(textOverlay.value, {
        width: '0',
        duration: 0.1
      })
      gsap.to(title.value, {
        x: '-130%',
        duration: 0.1
      })
      gsap.to(subtitle.value, {
        x: '-130%',
        duration: 0.1
      })
      // gsap.to(label.value, { opacity: 0, duration: 0.2 })
    }

    //* LIFECYCLE HOOKS
    onMounted(() => {
      // console.log("label mounted")
    })

    //* WATCHES
    watch(
      () => props.x,
      (first, second) => {
        if (window.innerWidth > 1024) {
          label.value.style.left = second + 'px'
        }
      }
    )
    watch(
      () => props.y,
      (first, second) => {
        if (window.innerWidth > 1024) {
          label.value.style.top = second + 'px'
        }
      }
    )
    return {
      label,
      labelIcon,
      lineV,
      lineH,
      textOverlay,
      title,
      subtitle,
      titleData,
      subtitleData,
      iconData,
      tl,
      initAnim,
      deleteAnim
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  position: fixed;
  top: 50%;
  left: 50%;
  font-family: $oswald;
  pointer-events: none;
  text-transform: uppercase;
  @include transform(translate(0, -140%));

  .label__lines {
    position: relative;
    width: 0;
    height: 0;

    .label__line-h {
      width: 0;
      height: 1px;
      background-color: $secundary;
    }

    .label__line-v {
      height: 0;
      width: 1px;
      background-color: $light;
    }
  }

  $label: 60px;

  .label__content {
    position: relative;
    overflow: hidden;
    margin: 5px;
    height: $label;
    display: flex;

    .label__icon {
      position: relative;
      width: 0;
      height: $label;
      background-color: rgba($dark, 0.4);
      // display: flex;
      // justify-content: center;
      // align-items: center;
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: $label / 1.5;
        @include transform(translate(-50%, -50%));
      }
    }

    .label__text {
      height: $label;
      overflow: hidden;
      display: flex;
      justify-content: center;
      flex-flow: column nowrap;
      margin-left: 5px;

      .label__text-overlay {
        position: absolute;
        height: 100%;
        width: 0;
        background-color: rgba($dark, 0.4);
      }

      .label__title {
        font-size: 24px;
        line-height: 1;
        margin: 0;
        color: $light;
        padding: 0 0.5rem;
        white-space: nowrap;
        @include transform(translateX(-150%));
      }

      .label__subtitle {
        font-size: 17px;
        line-height: 1;
        margin: 0;
        color: $orange;
        padding: 0 0.5rem;
        @include transform(translateX(-150%));
      }
    }
  }

  @include breakpoint(m) {
    top: auto;
    bottom: 30px;
    @include transform(translate(-50%, 0));

    .label__lines {
      display: none;
    }
  }

  @include breakpoint(o-h) {
    top: auto;
    bottom: 15px;
    @include transform(translate(-50%, 0));

    .label__lines {
      display: none;
    }
  }
}
</style>
