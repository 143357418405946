<template>
  <div class="video" ref="root">
    <div class="video__wrapper slideAnim">
      <carousel :wrap-around="true" ref="slide">
        <slide v-for="slide in content.gifs" :key="slide">
          <div class="carousel__item">
            <img :src="slide" alt="" />
          </div>
        </slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
    <div class="video__title">
      <h2 class="itemAnim">{{ title }}</h2>
      <p class="itemAnim">{{ subtitle }}</p>
    </div>
  </div>
  <div class="video__player" @click="playSound">
    <img
      src="https://res.cloudinary.com/nancloud/image/upload/v1627515374/NRW/WEB/ICONS/VOLUME_xypxdx.svg"
      alt=""
      v-if="playing"
    />
    <img
      src="https://res.cloudinary.com/nancloud/image/upload/v1627515374/NRW/WEB/ICONS/MUTE_otkapd.svg"
      alt=""
      v-if="!playing"
    />
    <audio loop ref="audio">
      <source
        :src="content.audio"
        type="audio/mpeg"
      />
    </audio>
  </div>
</template>

<script>
import './../assets/VENDORS/CSS/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import imagesLoaded from 'imagesloaded'
import gsap from 'gsap'
import { ref, onMounted } from 'vue'

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  props: {
    title: {
      type: String,
      default: 'UNESCO-Welterbe Zollverein'
    },
    subtitle: {
      type: String,
      default: 'Essen, 1995'
    },
    content: {
      type: Object
    }
  },
  emits: ['loaded'],
  setup (props, context) {
    const root = ref(null)
    const audio = ref(null)
    const playing = ref(false)
    const slide = ref(null)

    let items
    let slideElem, slidePagination, slideLength, slidePaginationActive

    onMounted(() => {
      items = root.value.querySelectorAll('.itemAnim')
      slideElem = root.value.querySelector('.slideAnim')
      slidePagination = root.value.querySelectorAll(
        '.carousel__pagination-item'
      )
      slidePagination = gsap.utils.toArray(slidePagination)
      slideLength = slidePagination.length
      slidePaginationActive = root.value.querySelector(
        '.carousel__pagination-button--active'
      )

      gsap.set(slideElem, {
        opacity: 0
      })
      items.forEach((element) => {
        gsap.set(element, {
          opacity: 0
        })
      })
      slidePagination.forEach((element) => {
        gsap.set(element, {
          width: 100 / slideLength + '%'
        })
      })
      gsap.set(slidePaginationActive, {
        width: 100 / slideLength + '%'
      })
      imagesLoaded(slide.value, function (instance) {
        context.emit('loaded')
        items.forEach((element, index) => {
          gsap.to(element, {
            opacity: 1,
            duration: 0.5,
            delay: 1.5 + index / 5,
            onComplete: () => {
              showSlide()
              audio.value.play()
              playing.value = true
            }
          })
        })
      })
    })

    const showSlide = () => {
      gsap.to(slideElem, {
        opacity: 1,
        duration: 0.5
      })
    }

    const playSound = () => {
      if (playing.value) {
        audio.value.pause()
        playing.value = false
      } else {
        audio.value.play()
        playing.value = true
      }
    }
    return {
      root,
      slideElem,
      audio,
      playing,
      slide,
      playSound
    }
  }
}
</script>

<style lang="scss" scoped>
$resX: 1636;
$resY: 920;
$h: calc(100vh - 160px);
$w: calc((100vh - 160px) * (1636 / 920));
$w-margin: calc((100vw - ((100vh - 160px) * (1636 / 920))) / 2);
.video {
  height: 100%;
  width: 10%;
  display: grid;
  grid-template-columns: [col-start] 110px [col-1] calc(100vw - 220px) [col-margin] 110px [col-end];
  grid-template-rows: [row-start] 110px [row-1] calc(100vh - 160px) [row-2] 50px [row-end];
  grid-template-areas:
    ". title ."
    ". video ."
    ". . .";

  .video__wrapper {
    grid-area: video;
    place-self: center;
    width: 80%;

    .carousel__item {
      background-color: transparent;
      color: var(--carousel-color-white);
      font-size: 20px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        // height: $h;
        width: 100%;
      }
    }
  }
  .carousel__prev,
  .carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
  }

  .video__title {
    grid-area: title;
    place-self: start;
    width: fit-content;
    height: fit-content;
    margin-top: 30px;

    h2 {
      font-family: $lora;
      font-size: 17px;
      margin: 0;
      color: $primary;
    }

    p {
      font-family: $fira;
      font-size: 15px;
      margin: 0;
    }

    .video__title-line {
      margin-top: 1rem;
      width: 100%;
      height: 1px;
      background-color: $dark;
    }
  }

  @include breakpoint(m) {
    $w: calc(100vw - 60px);
    $h: calc((100vw - 60px) * (9 / 16));

    .video__wrapper {
      width: $w;
      .carousel__item {
        img {
          // height: $h;
          width: 100%;
        }
      }
    }
  }

  @include breakpoint(sm) {
    grid-template-columns: [col-start] 30px [col-1] calc(100vw - 60px) [col-margin] 30px [col-end];
    grid-template-rows: [row-start] 150px [row-1] calc(100% - 210px) [row-2] 50px [row-end];

    .video__title {
      place-self: end center;
      p {
        text-align: center;
      }
    }
  }

  @include breakpoint(o-h) {
    grid-template-columns: [col-start] 80px [col-1] calc(100vw - 160px) [col-margin] 80px [col-end];
    grid-template-rows: [row-start] 30px [row-1] calc(100% - 60px) [row-2] 30px [row-end];

    $w: calc(57vw);
    $h: calc((57vw) * (9 / 16));

    .video__wrapper {
      width: $w;
      .carousel__item {
        img {
          height: $h;
        }
      }
    }

    .video__title {
      display: none;
    }
  }
}

.video__player {
  position: absolute;
  top: 30px;
  right: 85px;
  width: 46px;
  height: 46px;
  border: 1px solid $dark;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50%;
    opacity: 0.5;
    @include transition(opacity 0.5s);
  }

  &:hover {
    img {
      opacity: 1;
    }
  }
}
</style>
