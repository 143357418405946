<template>
  <div class="menu__container" ref="menu">
    <div class="menu__social">
      <div class="menu__social-icons">
        <a
          class="social"
          href="http://"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://res.cloudinary.com/nancloud/image/upload/v1624833678/NRW/WEB/ICONS/INSTAGRAM_ogfezp.svg"
            alt="Instagram"
            width="30"
          />
        </a>
        <a
          class="social"
          href="http://"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://res.cloudinary.com/nancloud/image/upload/v1624833677/NRW/WEB/ICONS/FACEBOOK_scvadc.svg"
            alt="Facebook"
            width="30"
          />
        </a>
        <a
          class="social"
          href="http://"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://res.cloudinary.com/nancloud/image/upload/v1624833677/NRW/WEB/ICONS/TWITTER_sbisen.svg"
            alt="Twitter"
            width="30"
          />
        </a>
      </div>
      <div class="menu__social-data">
        <p class="contact social">Kontakt:</p>
        <p class="title social">Loreto Quijada</p>
        <p class="content social">Creative Director & Filmemacherin</p>
        <a class="content social" href="https://loreto-quijada.jimdosite.com/"
          >loreto-quijada.jimdosite.com</a
        >
        <p class="title margin social">Freakground Interactive</p>
        <p class="content social">Web Design & Development</p>
        <a class="content social" href="http://lightskillsdojo.art/"
          >lightskillsdojo.art</a
        >
         <p class="title margin social">Henning Schmitz / Studio X.1</p>
        <p class="content social">Audio Postproduktion & VO</p>
        <a class="content social" href="https://www.xpoint1.de/"
          >xpoint1.de</a
        >
        <button class="credits social" @click="showCredits">Danksagung</button>
        <p class="quote margin social">All rights reserved.</p>
        <p class="quote social">2021</p>
      </div>
    </div>
    <div class="menu__line"></div>
    <div class="menu__desc">
      <div class="menu__desc-title">
        <h3 class="title desc">Über diese Betaversion</h3>
        <span class="date desc">23.08.2021 - 23.08.1946</span>
      </div>
      <div class="menu__desc-content">
        <p class="desc">
          Mit einem audiovisuellen Rückblick auf die Orte, Ereignisse und
          Aktionen, die diese Region zu einem medialen Zentrum und einer
          kulturellen Referenz in Deutschland werden ließen, würdigen wir die
          dynamische Vision, die das NRW, wie wir es heute kennen, geprägt hat.
        </p>
        <p class="desc">
          Die "75 Jahre NRW Jubiläum Karte" ist eine Webdoku, die durch ihre
          Interaktivität diesen Strukturwandel wieder aufleben lässt. Sie ist
          ein Online-Erlebnis, das den tiefgreifenden Wandel in einer der
          erfolgreichsten Mega-Regionen Europas reflektiert und zelebriert.
        </p>
      </div>
      <div class="menu__desc-quote">
        <p class="desc">Dieser Prototyp wurde gefördert durch</p>
        <img
          class="desc"
          src="https://res.cloudinary.com/nancloud/image/upload/v1626636975/NRW/WEB/MENU/NRWLOGO_iupw5x.svg"
          alt="Ministerium für Kultur und Wissenshaft des Landes Nordrhein-Westfalen"
          width="390"
          height="90"
        />
      </div>
    </div>
    <div class="credits__container">
      <div class="credits__menu">
        <ul>
          <li class="animElem" @click="goToCredit('zollverein')">
            Zeche Zollverein
          </li>
          <li class="animElem" @click="goToCredit('jahrhundderthalle')">
            Jahrhunderthalle
          </li>
          <li class="animElem" @click="goToCredit('kokerei')">Kokerei Hansa</li>
          <li class="animElem" @click="goToCredit('landshaftpark')">
            Landschaftspark
          </li>
          <li class="animElem" @click="goToCredit('industriemuseum')">
            Industriemuseum
          </li>
          <li class="animElem" @click="goToCredit('exter')">
            Exter & Begatalbahn
          </li>
          <li class="animElem" @click="goToCredit('zeche')">
            Zeche Consolidation
          </li>
        </ul>
      </div>
      <div class="credits__content">
        <div class="credits__title">
          <h3 class="titles animElem">Danksagung</h3>
          <span class="date animElem">23.08.2021 - 23.08.1946</span>
        </div>
        <h4 class="title animElem" id="zollverein">ZECHE ZOLLVEREIN</h4>
        <p class="text animElem">
          Das Fotoalbum vom Zollverein sollte eine Zeitachse schaffen, auf der
          man die wunderbare Entwicklung von einer Zeche zu einem bekannten
          Museum sehen kann. Leider sind die meisten der historischen Bilder vom
          Zollverein (und anderen industriellen Orten!) in Privatbesitz und die
          Rechte müssen jährlich bezahlt werden, um die Fotos online stellen zu
          können. An diejenigen, die unentgeltlich und symbolisch zu diesem
          Album beigetragen haben: vielen Dank!
        </p>
        <p class="text animElem">
          Vielen lieben Dank an Herrn <b>Jochen Tack</b> für seine wunderbaren
          Bilder. Um seine ganze Sammlung aus verschiedenen Städten zu sehen,
          besuchen Sie bitte:
        </p>
        <a
          class="link no-margin animElem"
          href="https://www.tack-fotografie.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          tack-fotografie.de
        </a>
        <p class="text animElem">
          Und hier können Sie einen Kalender bestellen, der aus seinen Bildern
          zusammengestellt wurde!
        </p>
        <a
          class="link animElem"
          href="https://www.fotogalerie-jochentack.de/3948040/weltweit?l=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          fotogalerie-jochentack.de
        </a>
        <p class="text animElem">
          Besonderen Dank auch an Herrn <b>Dieter Blase</b>, der mir
          freundlicherweise erlaubt hat, seine Schwarzweißfotos vom Zollverein
          zu teilen. Er hat ein bemerkenswertes Portfolio hier:
        </p>
        <a
          class="link no-margin animElem"
          href="https://www.fotogruppe-oculus.de/portfolios/dieter-blase/"
          target="_blank"
          rel="noopener noreferrer"
        >
          fotogruppe-oculus.de/portfolios/dieter-blase
        </a>
        <p class="text animElem">Und eine Kurzbiografie hier:</p>
        <a
          class="link animElem"
          href="https://www.artistravel.eu/dozenten/dieter-blase.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          artistravel.eu/dozenten/dieter-blase
        </a>
        <p class="text animElem">
          Herr <b>Christoph Jürgens</b> hat uns freundlicherweise an seiner
          künstlerischen Arbeit im schönsten Bergwerk der Welt teilhaben lassen!
          Er hat auch ein umfangreiches Portfolio im gesamten Ruhrgebiet, bitte
          besuchen Sie hier für weitere Orte:
        </p>
        <a
          class="link animElem"
          href="http://www.licht-kunst-industriekultur.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          licht-kunst-industriekultur.de
        </a>
        <p class="text animElem">
          Die Community von <b>Lokalkompass</b> half dabei, einen historischen
          Kontext zur Zeche herzustellen. Vielen Dank an diejenigen, die mit
          ihren persönlichen Archiven beigetragen haben, ihre Profile finden Sie
          hier:
        </p>
        <a
          class="link animElem"
          href="https://www.lokalkompass.de/essen-nord/profile-11718/karlheinz-freudenberg"
          target="_blank"
          rel="noopener noreferrer"
        >
          Karlheinz Freudenberg
        </a>
        <br /><br />
        <a
          class="link animElem"
          href="https://www.lokalkompass.de/essen-steele/profile-10884/norbert-janz"
          target="_blank"
          rel="noopener noreferrer"
        >
          Norbert Janz
        </a>
        <br /><br />
        <a
          class="link animElem"
          href="https://www.lokalkompass.de/essen-sued/profile-58602/bodo-helmut-kannacher"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bodo-Helmut Kannacher
        </a>
        <br /><br />
        <a
          class="link animElem"
          href="https://www.lokalkompass.de/bottrop/profile-44571/wolfgang-quast/2"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wolfgang Quast
        </a>
        <br /><br />
        <a
          class="link animElem"
          href="https://www.lokalkompass.de/essen-ruhr/profile-95070/thomas-ruszkowski"
          target="_blank"
          rel="noopener noreferrer"
        >
          Thomas Ruszkowski
        </a>
        <br /><br />
        <a
          class="link animElem"
          href="https://www.lokalkompass.de/essen-nord/profile-28677/bodo-urbat?type=article"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bodo Urbat
        </a>
        <br /><br />
        <a
          class="link animElem"
          href="https://www.lokalkompass.de/essen-kettwig/profile-93849/in-gedenken-an-hanns-peter-huester?type=article"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hanns-Peter Hüster
        </a>
        <p class="text animElem">
          Dank an den Landschaftsverband Rheinland LVR für den Umgang mit den
          älteren Fotos und Lageplänen und auch Annika Fiestelmann von Stadt
          Essen.
        </p>
        <p class="text">
          Und natürlich ein großes Dankeschön an diejenigen, die in zahlreichen
          Websites kooperiert haben, die Inhalte mit Creative Commons teilen,
          wissen, dass Ihre Kunst und Solidarität sehr geschätzt wird!
        </p>
        <p class="text">
          Dieses Projekt wäre ohne jeden von Ihnen nicht möglich gewesen:
        </p>
        <p class="text">Alena Hanzlová</p>
        <p class="text">AlterVista</p>
        <p class="text">
          mindat.org -
          <a
            class="link"
            href="https://www.mindat.org/loc-17709.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            support here!
          </a>
        </p>
        <p class="text">Unukorno</p>
        <p class="text">
          Thomas Mayer - Bilderfundus FREIWILD - Büro für Kommunika
        </p>
        <p class="text">avda</p>
        <p class="text">Hans Blossey</p>
        <p class="text">Островский Александр</p>
        <p class="text">Martin Falbisoner</p>
        <p class="text">Günter Seggebäing, CC BY-SA 3.0</p>
        <p class="text">Frank Vinken</p>
        <p class="text">Thomas_Willemsen</p>
        <p class="text">Sven Lorenz</p>
        <p class="text">Matthias-Duschner</p>
        <p class="text">Thomas_Willemsen</p>
        <p class="text">Thomas Wolf</p>
        <h4 class="title" id="jahrhundderthalle">JAHRHUNDERTHALLE</h4>
        <p class="text">
          Mein aufrichtiger Dank gilt dem WorldBeatClub für die Erlaubnis, ihre
          Drum-Session in der Bochumer Jahrhunderthalle aufzunehmen,
          insbesondere Ricardo für seine Freundlichkeit und Einsatzbereitschaft.
        </p>
        <a
          class="link"
          href="https://www.facebook.com/WorldBeatClubTanzenUndHelfen/?ti=as"
          target="_blank"
          rel="noopener noreferrer"
        >
          WorldBeatClub
        </a>
        <p class="text">
          Und an diejenigen, die ihr Videomaterial zur Verfügung gestellt haben:
        </p>
        <a
          class="link"
          href="https://www.youtube.com/watch?v=X44EP2kxVTE"
          target="_blank"
          rel="noopener noreferrer"
        >
          Manny FPV / “Jahrhunderthalle Bochum, Gefilmt mit der DJI Mavic Pro”
        </a>
        <br /><br />
        <a
          class="link"
          href="https://www.youtube.com/watch?v=bc8QEN7j2VI"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bullettime / “Bochum von oben (Jahrhunderthalle)”
        </a>
        <br /><br />
        <a
          class="link"
          href="https://www.youtube.com/watch?v=A3O3nk0disI"
          target="_blank"
          rel="noopener noreferrer"
        >
          Rudolf Brinkmöller / “Jahrhunderthalle Bochum im Westpark Bochum”
        </a>
        <p class="text">
          Rudolf hat interessante Fotos von der Gegend hier bereitgestellt:
        </p>
        <a
          class="link"
          href="https://www.ruhrgebiet-industriekultur.de/westpark.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          ruhrgebiet-industriekultur.de/westpark.html
        </a>
        <p class="text">
          Vielen Dank auch an den Fotografen Christoph Jürgens, der unzählige
          Orte entlang der Industriestraße fotografiert hat. Besuchen Sie seine
          Website hier!
        </p>
        <a
          class="link"
          href="http://www.licht-kunst-industriekultur.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          licht-kunst-industriekultur.de
        </a>
        <p class="text">
          Inspiriert durch die fragmentarische Fotografie von Bruce T. Martin:
        </p>
        <a
          class="link"
          href="https://www.brucetmartin.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          brucetmartin.com
        </a>
        <h4 class="title" id="kokerei">KOKEREI HANSA</h4>
        <p class="text">
          Artwork von
          <a
            class="link"
            href="https://www.behance.net/MarcoValdesP"
            target="_blank"
            rel="noopener noreferrer"
          >
            Marco Andrés Valdés Paillaqueo
          </a>
        </p>
        <p class="text">
          Historische Abbildung der deutschen Stadt Dortmund von Georg Braun and
          Franz Hogenberg (zwischen 1572 und 1618)
        </p>
        <p class="text">St. Marys und St. Reinolds in 1470</p>
        <p class="text">Dortmund um 1610 von Detmar Muhler</p>
        <p class="text">Alte Dortmund YouTube channel von oldstuff4all:</p>
        <a
          class="link"
          href="https://www.youtube.com/channel/UCyuMuYW1rD8N3BMJB_TASBg"
          target="_blank"
          rel="noopener noreferrer"
        >
          Youtube
        </a>
        <p class="text">Bilder:</p>
        <p class="text">
          Dortmund Stadarchiv<br />Ruhrnachrichten<br />Kreikenbohm<br />Christian
          Westphalen
        </p>
        <p class="text">Aus Flickr:</p>
        <p class="text">
          Eelco<br />
          Bastian Güldner<br />
          Carsten Rehmann<br />
          cristiano<br />
          Katja<br />
          Uwe Zierke<br />
          Mario P<br />
          K. Edinger<br />
        </p>
        <p class="text">Aus Wikipedia:</p>
        <p class="text">
          Arnoldius<br />
          Christian A. Schröder<br />
          Rainer Halama<br />
          Toby001<br />
          Braun-Hogenberg<br />
          Detmar Muhler<br />
          Kupferstich Merian<br />
          Julius Tempeltey<br />
          John Vachon<br />
        </p>
        <h4 class="title" id="landshaftpark">LANDSCHAFTSPARK</h4>
        <p class="text">
          Dieses Video wäre nicht dasselbe ohne die wertvollen Wasseraufnahmen
          von Robert Hughan, vielen Dank, Goofy!
        </p>
        <p class="text">
          Besuchen Sie die Webseite von Goofy Aqua Video Underwater Images
        </p>
        <a
          class="link"
          href="https://goofyaquavideo.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          goofyaquavideo.com
        </a>
        <a
          class="link"
          href="https://www.onderwaterfilmer.nl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          onderwaterfilmer.nl
        </a>
        <a
          class="link"
          href="https://www.velegezichtenvandezevenhuizerplas.nl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          velegezichtenvandezevenhuizerplas.nl
        </a>
        <p class="text">
          Ich habe die Unterwassergeräusche hier heruntergeladen:
        </p>
        <a
          class="link"
          href="https://freesound.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          freesound.org
        </a>
        <p class="text">hier können Sie spenden:</p>
        <a
          class="link"
          href="https://freesound.org/donations/donate/"
          target="_blank"
          rel="noopener noreferrer"
        >
          freesound.org/donations/donate
        </a>
        <p class="text">
          "Bird Whistling, A.wav" + "Bubbling, Large, A.wav" von
          <a
            class="link"
            href="https://www.jshaw.co.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            InspectorJ
          </a>
        </p>
        <p class="text">
          “bike sound” von
          <a
            class="link"
            href="https://freesound.org/people/junioraragaki/"
            target="_blank"
            rel="noopener noreferrer"
          >
            junioraragaki
          </a>
        </p>
        <p class="text">
          “bubbles” von
          <a
            class="link"
            href="https://freesound.org/people/Perel/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Perel
          </a>
        </p>
        <p class="text">
          “Deep water SFX” von
          <a
            class="link"
            href="https://freesound.org/people/TypOnNegatywny/"
            target="_blank"
            rel="noopener noreferrer"
          >
            TypOnNegatywny
          </a>
          &
          <a
            class="link"
            href="https://freesound.org/people/NoiseCollector/"
            target="_blank"
            rel="noopener noreferrer"
          >
            NoiseCollector
          </a>
          &
          <a
            class="link"
            href="https://freesound.org/people/yosarrian/"
            target="_blank"
            rel="noopener noreferrer"
          >
            yosarrian
          </a>
        </p>
        <p class="text">
          “water fountain” von
          <a
            class="link"
            href="https://freesound.org/people/iut_Paris8/"
            target="_blank"
            rel="noopener noreferrer"
          >
            iut_Paris8
          </a>
        </p>
        <p class="text">
          “underwater composition” von
          <a
            class="link"
            href="https://freesound.org/people/hisoul/"
            target="_blank"
            rel="noopener noreferrer"
          >
            hisoul
          </a>
        </p>
        <h4 class="title" id="industriemuseum">INDUSTRIEMUSEUM</h4>
        <p class="text">Fotos von <b>Loreto Quijada</b></p>
        <p class="text">Bildverarbeitung: Juan Manuel Aburto - @cincoojoscl</p>
        <a
          class="link"
          href="https://cincoojos.cl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          cincoojos.cl
        </a>
        <h4 class="title" id="exter">EXTER & BEGATALBAHN</h4>
        <p class="text">
          Artwork von Giovanni Longo Muñoz @giovanni_longo_munoz
        </p>
        <p class="text">Bilder</p>
        <a
          class="link"
          href="https://de.wikipedia.org/wiki/Benutzer:Christian_Schulz"
          target="_blank"
          rel="noopener noreferrer"
        >
          Christian Schulz
        </a>
        <br /><br />
        <a
          class="link"
          href="https://commons.wikimedia.org/wiki/User:A.Savin"
          target="_blank"
          rel="noopener noreferrer"
        >
          A.Savin
        </a>
        <p class="text">
          (
          <a
            class="link"
            href="https://commons.wikimedia.org/wiki/User_talk:A.Savin"
            target="_blank"
            rel="noopener noreferrer"
          >
            Wikimedia Commons
          </a>
          -
          <a
            class="link"
            href="http://wikiphoto.space/feedback/"
            target="_blank"
            rel="noopener noreferrer"
          >
            WikiPhotoSpace
          </a>
          )
        </p>
        <p class="text">M.Laudage</p>
        <a
          class="link"
          href="https://commons.wikimedia.org/wiki/User:Vogelsteller"
          target="_blank"
          rel="noopener noreferrer"
        >
          Benedikt Vogelsteller
        </a>
        <p class="text">R.Maritschnigg</p>
        <a
          class="link"
          href="https://commons.wikimedia.org/wiki/User:AxelHH"
          target="_blank"
          rel="noopener noreferrer"
        >
          Axel Hindemith
        </a>
        <br /><br />
        <a
          class="link"
          href="https://creativecommons.org/licenses/by-sa/3.0/de/legalcode"
          target="_blank"
          rel="noopener noreferrer"
        >
          Creative Commons CC-by-sa-3.0 de
        </a>
        <br /><br />
        <a
          class="link"
          href="https://commons.wikimedia.org/wiki/User:Vogelsteller"
          target="_blank"
          rel="noopener noreferrer"
        >
          P.Böhm, Slg B.Vogelsteller
        </a>
        <br /><br />
        <a
          class="link"
          href="https://commons.wikimedia.org/wiki/User:Hufi"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hufi
        </a>
        <p class="text">
          File: Extetertalbahn21.jpg<a
            class="link"
            href="https://creativecommons.org/licenses/by-sa/3.0/"
            target="_blank"
            rel="noopener noreferrer"
          >
            CC BY-SA 3.0
          </a>
          <br /><br />
          <a
            class="link"
            href="https://commons.wikimedia.org/wiki/User:Machahn"
            target="_blank"
            rel="noopener noreferrer"
          >
            Machahn
          </a>
          <br /><br />
          <a
            class="link"
            href="https://www.v100.de/index.php?nav=1000001&lang=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            V100 West
          </a>
        </p>
        <p class="text">
          Und vielen lieben Dank an Günter Lievrè für seine Unterstützung beim Sammeln von Material und Fotos über Züge.
        </p>
        <h4 class="title" id="zeche">ZECHE CONSOLIDATION</h4>
        <p class="text">
          Artwork von
          <a
            class="link"
            href="https://www.behance.net/MarcoValdesP"
            target="_blank"
            rel="noopener noreferrer"
          >
            Marco Andrés Valdés Paillaqueo
          </a>
        </p>
        <p class="text">Nick Stahlkocher</p>
        <a
          class="link"
          href="https://de.wikipedia.org/wiki/Benutzer:Rasi57"
          target="_blank"
          rel="noopener noreferrer"
        >
          Rasi57
        </a>
        <p class="text">Ulrich Nickel</p>
        <p class="text">Peabody Energy, Inc.</p>
        <p class="text">Audio Postproduktion und VO: Henning Schmitz / Studio X.1</p>
        <a
          class="link margin"
          href="https://www.xpoint1.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://res.cloudinary.com/loretolala/image/upload/v1629654988/WEB/SECTIONS/CREDITS/Logo_Studio_Xpunkt_yqblbd.jpg" alt="Audio Postproduktion und VO: Henning Schmitz / Studio X.1">
        </a>
        <br /><br /><br /><br /><br /><br /><br /><br />
      </div>
    </div>
  </div>
  <div class="menu__icon-wrapper" ref="menuBtn" @click="showMenu">
    <div class="menu__icon">
      <div class="menu__icon-line"></div>
      <div class="menu__icon-line"></div>
      <div class="menu__icon-line"></div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ref, onMounted } from 'vue'

gsap.registerPlugin(ScrollToPlugin)

export default {
  setup () {
    //* ELEMENTS
    const menu = ref(null)
    const menuBtn = ref(null)

    let socialItems, descItems, line, credits, creditsMenu, creditsItems

    //* VARIABLES
    const inAnim = ref(false)
    const open = ref(false)

    onMounted(() => {
      socialItems = menu.value.querySelectorAll('.social')
      descItems = menu.value.querySelectorAll('.desc')
      line = menu.value.querySelector('.menu__line')
      credits = menu.value.querySelector('.credits__container')
      creditsMenu = menu.value.querySelector('.credits__menu')
      creditsItems = credits.querySelectorAll('.animElem')
    })

    const showMenu = () => {
      if (!open.value && !inAnim.value) {
        gsap.to(menu.value, {
          left: '0%',
          duration: 1.5,
          onStart: () => {
            inAnim.value = true
            menuBtn.value.classList.add('close')
          },
          onComplete: () => {
            open.value = true
            inAnim.value = false
            animElems(true)
          }
        })
      } else if (open.value && !inAnim.value) {
        gsap.to(menu.value, {
          left: '-100%',
          duration: 1,
          onStart: () => {
            inAnim.value = true
            menuBtn.value.classList.remove('close')
            creditsMenu.classList.add('close')
          },
          onComplete: () => {
            creditsMenu.classList.remove('close')
            open.value = false
            inAnim.value = false
            animElems(false)
          }
        })
      }
    }

    const showCredits = () => {
      const creditsLength = creditsItems.length
      gsap.to(credits, {
        top: 0,
        duration: 1.5,
        onStart: () => {
          credits.style.display = 'grid'
        }
      })
      creditsItems.forEach((element, index) => {
        gsap.to(element, {
          opacity: 1,
          duration: 0.5,
          delay: index / creditsLength + 1.5
        })
      })
    }

    const goToCredit = (id) => {
      gsap.to(credits, {
        duration: 2,
        scrollTo: { y: '#' + id, offsetY: 50, autoKill: true }
      })
    }

    const animElems = (inAnim) => {
      if (inAnim) {
        const socialLength = socialItems.length
        const descLength = descItems.length
        socialItems.forEach((element, index) => {
          gsap.to(element, {
            opacity: 1,
            duration: 0.5,
            delay: index / socialLength
          })
        })
        descItems.forEach((element, index) => {
          gsap.to(element, {
            opacity: 1,
            duration: 0.5,
            delay: index / descLength
          })
        })
        gsap.to(line, {
          height: '100%',
          duration: 0.6
        })
      } else {
        socialItems.forEach((element) => {
          gsap.set(element, {
            opacity: 0
          })
        })
        descItems.forEach((element) => {
          gsap.set(element, {
            opacity: 0
          })
        })
        gsap.set(line, {
          height: '0'
        })
        gsap.set(credits, {
          top: '100vh'
        })
        credits.scrollTo(0, 0)
        menu.value.scrollTo(0, 0)
        credits.style.display = 'none'
        creditsItems.forEach((element) => {
          gsap.set(element, {
            opacity: 0
          })
        })
      }
    }

    return {
      menu,
      menuBtn,
      inAnim,
      showMenu,
      showCredits,
      goToCredit,
      animElems
    }
  }
}
</script>

<style lang="scss" scoped>
.menu__icon-wrapper {
  position: fixed;
  top: 30px;
  left: 30px;
  width: 46px;
  height: 46px;
  cursor: pointer;

  .menu__icon {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid $dark;
    background-color: transparent;
    transition: all 0.5s;

    .menu__icon-line {
      position: absolute;
      width: 35%;
      height: 1px;
      background: $dark;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0deg);
      transition: all 0.5s;

      &:first-child {
        top: 40%;
      }

      &:last-child {
        top: 60%;
      }
    }
  }

  &.close {
    .menu__icon {
      background-color: $secundary;
      .menu__icon-line {
        &:first-child {
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:last-child {
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:nth-child(2) {
          width: 0;
        }
      }
    }
  }

  &:hover {
    .menu__icon {
      .menu__icon-line {
        width: 30%;
        &:nth-child(2) {
          width: 50%;
        }
      }
    }

    &.close {
      .menu__icon {
        .menu__icon-line {
          width: 50%;
          &:nth-child(2) {
            width: 0%;
          }
        }
      }
    }
  }
}

.menu__container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;

  display: grid;
  grid-template-columns: [col-start] 10% [col-1] 1fr [col-margin] 50px [col-line] 50px [col-2] 2fr [col-end];
  grid-template-rows: [row-start] 10% [row-1] 1fr [row-margin] 10% [row-end];
  grid-template-areas:
    ". . . line ."
    ". social . line desc"
    ". . . line .";

  background-color: $secundary;

  .menu__social {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    // justify-content: space-around;
    justify-content: center;
    grid-area: social;

    .menu__social-icons {
      display: flex;
      flex-flow: column nowrap;
      display: none;

      a {
        margin-bottom: 0.6rem;
        cursor: pointer;

        img {
          opacity: 0.4;
          @include transition(opacity 0.5s);
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .menu__social-data {
      text-align: right;
      position: relative;

      .contact {
        font-family: $lora;
        font-size: 13px;
        color: $primary;
        margin-top: 0;
      }

      .title {
        font-family: $lora;
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 0.5rem;

        &.margin {
          margin-top: 1rem;
        }
      }

      .content {
        font-family: $fira;
        font-size: 15px;
        font-weight: 300;
        margin: 0;
        color: $dark;
      }

      .credits {
        display: block;
        position: absolute;
        right: 0;
        margin: 1.2rem 0;
        // width: fit-content;
      }

      .quote {
        font-family: $fira;
        font-size: 13px;
        font-weight: 300;
        margin: 0;
        &.margin {
          margin-top: 5rem;
        }
      }
    }
  }

  .menu__line {
    width: 1px;
    height: 0;
    background-color: $dark;
    grid-area: line;
  }

  .menu__desc {
    grid-area: desc;
    align-self: center;
    padding-right: 15%;
    .menu__desc-title {
      font-family: $lora;
      .title {
        font-size: 25px;
        font-weight: 400;
        color: $primary;
        margin: 0;
        text-align: left;
      }
      .date {
        font-size: 15px;
      }
    }

    .menu__desc-content,
    .menu__desc-quote {
      p {
        font-family: $fira;
        font-size: 15px;
      }
    }
  }

  .credits__container {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 100vh;
    background-color: $secundary;

    grid-template-columns:
      [col-start] 110px [col-1] calc(40vw - 90px)
      [col-margin] 40px [col-2] calc(60vw - 90px)
      [col-margin] 110px [col-end];
    grid-template-rows: [row-start] 110px [row-1] 1fr [row-4] 50px [row-end];
    grid-template-areas:
      ". . . . ."
      ". menu . content ."
      ". . . . .";
    overflow: auto;

    .credits__menu {
      grid-area: menu;
      position: fixed;
      top: 110px;
      left: 40vw;
      @include transform(translateX(-100%));

      &.close {
        position: absolute;
        @include transform(translateX(0));
        top: 0;
        right: 0;
        left: auto;
      }

      ul {
        list-style-type: none;
        text-align: right;
        margin: 0;

        li {
          font-family: $lora;
          color: $primary;
          cursor: pointer;
          @include transform(translateX(0));
          @include transition(transform 0.5s);

          &:hover {
            @include transform(translateX(10%));
          }
        }
      }
    }

    .credits__content {
      grid-area: content;
      margin-bottom: 4rem;

      .credits__title {
        font-family: $lora;

        .titles {
          font-size: 25px;
          font-weight: 400;
          color: $primary;
          margin: 0;
        }

        .date {
          font-size: 15px;
        }
      }

      .title {
        font-family: $fira;
        font-size: 16px;
        margin-top: 3rem;
        max-width: 68%;

        &.margin {
          margin-bottom: 10vh;
        }
      }

      .text {
        font-family: $fira;
        font-size: 15px;
        max-width: 68%;

        &.margin {
          margin-bottom: 10vh;
        }
      }

      .link {
        font-family: $fira;
        font-size: 15px;
        font-weight: 400;
        color: $primary;
        margin-bottom: 2rem;
        word-break: break-all;
        max-width: 68%;

        &.margin {
          margin-bottom: 10vh;
        }

        &.no-margin {
          margin-bottom: 0;
        }
      }
    }
  }

  @include breakpoint(m) {
    overflow: auto;
    grid-template-columns: [row-start] 30px [row-1] calc(100vw - 60px) [row-margin] 30px [row-end];
    grid-template-rows: [col-start] 100px [col-1] 1fr [col-margin] 50px [col-2] 2fr [col-end];
    grid-template-areas:
      ". . ."
      ". desc ."
      ". . ."
      ". social .";

    .menu__desc {
      padding-right: 0;
      text-align: justify;
      max-width: 480px;
      place-self: center;

      .menu__desc-quote {
        img {
          width: 100%;
        }
      }
    }

    .menu__social {
      place-self: center;
      align-items: center;

      .menu__social-icons {
        flex-flow: row nowrap;
        justify-content: center;

        a {
          margin-left: 0.6rem;
        }
      }

      .menu__social-data {
        margin-bottom: 2rem;
        text-align: center;
        .credits {
          right: auto;
          left: 50%;
          @include transform(translateX(-50%));
        }
      }
    }

    .credits__container {
      grid-template-columns:
        [col-start] 30px [col-1] 0
        [col-margin] 0 [col-2] calc(100vw - 60px)
        [col-margin] 30px [col-end];

      text-align: center;

      .credits__menu {
        display: none;
      }

      .title,
      .text,
      .link {
        max-width: 100% !important;
      }
    }
  }
}

.social,
.desc,
.animElem {
  opacity: 0;
}
</style>
