<template>
  <div id="map" ref="root"></div>
  <div class="map__label">
    <h1><span>75</span> Jahre NRW Jubiläum Karte</h1>
    <p>Eine digitale Feier</p>
  </div>
  <Label :x="mouse.x" :y="mouse.y" ref="label" />
  <Content ref="content" @closeContent="cameraZoom(false, null)" />
</template>

<script>
import Label from './Label'
import Content from './Content'

// import Stats from 'stats.js'
import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import GlslCanvas from 'glslCanvas'
import { Power4, gsap } from 'gsap'

import mapFragShader from '@/assets/GLSL/mapFragShader.glsl'
// import skyFragShader from '@/assets/GLSL/skyFragShader.glsl'
// import vertexShader from '@/assets/GLSL/vertexShader.glsl'
import { ref, onMounted, onBeforeMount } from 'vue'

import diffuseMap from '@/assets/IMG/color_map.webp'

import map1 from '@/assets/IMG/01_map.webp'
import map2 from '@/assets/IMG/02_map.webp'
import map3 from '@/assets/IMG/03_map.webp'
import map4 from '@/assets/IMG/04_map.webp'
import map5 from '@/assets/IMG/05_map.webp'
import map6 from '@/assets/IMG/06_map.webp'
import map7 from '@/assets/IMG/07_map.webp'

export default {
  components: {
    Label,
    Content
  },
  emits: ['loaded'],
  setup (props, context) {
    //* ELEMENTS
    const root = ref(null)
    const label = ref(null)
    const content = ref(null)

    //* DIMENSIONS
    let BOUNDS, WIDTH, HEIGHT, ORIENTATION
    let activatedPlaces = 0

    //* STATS
    // const stats = new Stats()
    // stats.showPanel(0)
    // document.body.appendChild(stats.dom)

    //* MANAGER
    const manager = new THREE.LoadingManager()
    // manager.onStart = function (url, itemsLoaded, itemsTotal) {
    //   console.log(
    //     'Started loading file: ' +
    //       url +
    //       '.\nLoaded ' +
    //       itemsLoaded +
    //       ' of ' +
    //       itemsTotal +
    //       ' files.'
    //   )
    // }

    manager.onLoad = function () {
      // console.log('Loading Complete!')
      context.emit('loaded')
    }

    // manager.onProgress = function (url, itemsLoaded, itemsTotal) {
    //   console.log(
    //     'Loading file: ' +
    //       url +
    //       '.\nLoaded ' +
    //       itemsLoaded +
    //       ' of ' +
    //       itemsTotal +
    //       ' files.'
    //   )
    // }

    manager.onError = function (url) {
      console.log('There was an error loading ' + url)
    }

    //* SCENE
    const scene = new THREE.Scene()

    //* RENDERER
    const renderer = new THREE.WebGLRenderer({ antialias: true })
    renderer.setClearColor(0xece4db)
    renderer.setPixelRatio(window.devicePixelRatio)

    //* CAMERA
    const camera = new THREE.PerspectiveCamera(
      50,
      window.innerWidth / window.innerHeight,
      0.1,
      20
    )
    let cameraInZoom = false
    let reqMap
    let posIniCamera

    //* GEOMETRIES
    const geoPoint = new THREE.BoxGeometry(0.0002, 0.0002, 0.0002)
    const geoBg = new THREE.PlaneGeometry(5, 2.5)
    geoBg.attributes.uv2 = geoBg.attributes.uv
    const geoCard = new THREE.PlaneGeometry(1.15, 1.15)
    geoCard.attributes.uv2 = geoCard.attributes.uv
    const geoMap = new THREE.PlaneBufferGeometry(0.9, 0.9, 32, 32)
    geoMap.attributes.uv2 = geoMap.attributes.uv
    // const geoSky = new THREE.PlaneBufferGeometry(0.83, 0.83, 1, 1)

    //* TEXTURES
    const shadowBg = new THREE.TextureLoader().load(
      'https://res.cloudinary.com/nancloud/image/upload/v1627597726/NRW/WEB/MODELS/TEXTURES/bg_shadows_cnn9an.webp'
    )
    const shadowCard = new THREE.TextureLoader().load(
      'https://res.cloudinary.com/nancloud/image/upload/v1627597726/NRW/WEB/MODELS/TEXTURES/card_shadows_o03tmy.webp'
    )
    const heightMap = new THREE.TextureLoader().load(
      'https://res.cloudinary.com/nancloud/image/upload/v1627597889/NRW/WEB/MODELS/TEXTURES/height_map_zyopdq.webp'
    )
    const normalMap = new THREE.TextureLoader().load(
      'https://res.cloudinary.com/loretolala/image/upload/v1629653965/WEB/MODEL/TEXTURES/normal_map_lsmaxx.webp'
    )
    const aoMap = new THREE.TextureLoader().load(
      'https://res.cloudinary.com/nancloud/image/upload/v1627598176/NRW/WEB/MODELS/TEXTURES/ao_map_qdszpb.webp'
    )

    //* CREATE MAP TEXTURE IN CANVAS GLSL
    const ctx = document.createElement('canvas')
    ctx.width = 1024
    ctx.height = 1024
    const sandbox = new GlslCanvas(ctx)
    sandbox.load(mapFragShader)
    sandbox.setUniform('u_tex0', diffuseMap)
    sandbox.setUniform('u_tex1', map1)
    sandbox.setUniform('u_tex2', map2)
    sandbox.setUniform('u_tex3', map3)
    sandbox.setUniform('u_tex4', map4)
    sandbox.setUniform('u_tex5', map5)
    sandbox.setUniform('u_tex6', map6)
    sandbox.setUniform('u_tex7', map7)
    const colorMap = new THREE.CanvasTexture(ctx)

    //* MATERIALS
    const materialPoint = new THREE.MeshBasicMaterial({
      opacity: 0.0,
      transparent: true
    })
    const materialBg = new THREE.MeshStandardMaterial({
      aoMap: shadowBg
    })
    const materialCard = new THREE.MeshStandardMaterial({
      aoMap: shadowCard
    })
    const materialMap = new THREE.MeshStandardMaterial({
      map: colorMap,
      aoMap: aoMap,
      displacementMap: heightMap,
      displacementScale: 0.05,
      normalMap: normalMap
    })
    // const materialSky = new THREE.ShaderMaterial({
    //   uniforms: {
    //     u_time: { value: 1.0 },
    //     u_resolution: { value: new THREE.Vector2(1024, 1024) }
    //   },
    //   vertexShader: vertexShader,
    //   fragmentShader: skyFragShader,
    //   defines: {
    //     PR: window.devicePixelRatio.toFixed(1)
    //   }
    // })
    // materialSky.transparent = true

    //* MESHES
    const meshPoint = new THREE.Mesh(geoPoint, materialPoint)
    meshPoint.position.set(0, 0, 0)
    meshPoint.name = ''
    scene.add(meshPoint)

    const meshBg = new THREE.Mesh(geoBg, materialBg)
    meshBg.position.z = -0.3
    meshBg.name = ''
    scene.add(meshBg)

    const meshCard = new THREE.Mesh(geoCard, materialCard)
    meshCard.position.z = -0.2
    meshCard.name = ''
    scene.add(meshCard)

    const meshMap = new THREE.Mesh(geoMap, materialMap)
    meshMap.name = ''
    scene.add(meshMap)

    // const meshSky = new THREE.Mesh(geoSky, materialSky)
    // meshSky.name = ''
    // meshSky.position.z = 0.05
    // scene.add(meshSky)

    //* LIGHTS
    var ambient = new THREE.AmbientLight(0xbead97, 0.55)
    scene.add(ambient)

    const pointLight = new THREE.PointLight(0xffffff, 0.5, 1000)
    pointLight.position.set(-1.5, 1, 5)
    scene.add(pointLight)

    //* RAYCASTING
    const raycaster = new THREE.Raycaster()
    const mouse = ref(new THREE.Vector2())
    const mouseMap = new THREE.Vector2()
    let INTERSECTED

    //* CLASES

    class Place {
      constructor (scene, position, name, id) {
        //* ICON
        this.icon = iconBase.clone()

        //* ICON SETUP
        if (window.innerWidth <= 1024) {
          // this.icon.scale.set(0.06, 0.06, 0.06)
          this.icon.scale.set(0.058, 0.058, 0.058)
        } else {
          this.icon.scale.set(0.015, 0.015, 0.015)
        }
        this.icon.position.set(position.x, position.y + 0.03, 0.06)
        this.icon.rotation.x =
          window.innerWidth > 1024
            ? (70 * Math.PI) / 180
            : (90 * Math.PI) / 180
        this.icon.name = name

        this.darkMaterial = new THREE.MeshStandardMaterial({
          color: 'grey',
          opacity: 1,
          transparent: true
        })
        this.lightMaterial = new THREE.MeshStandardMaterial({
          color: 0xffa920,
          emissive: 0x7b4a00
        })
        this.icon.material = this.darkMaterial

        this.features = { r: 0.0 }
        this.id = id
        this.opened = false

        scene.add(this.icon)
      }

      open () {
        this.icon.material = this.lightMaterial
        label.value.initAnim(this.icon.name)
        gsap.to(this.features, {
          r: 100.0,
          duration: 2.5,
          onUpdate: () => {
            sandbox.setUniform('u_radio' + this.id, this.features.r)
          }
        })
      }

      close () {
        label.value.deleteAnim()
        if (!this.opened) {
          this.icon.material = this.darkMaterial
          gsap.killTweensOf(this.features)
          gsap.to(this.features, {
            r: 0.0,
            onUpdate: () => {
              sandbox.setUniform('u_radio' + this.id, this.features.r)
            }
          })
        }
      }

      activated () {
        this.opened = true
      }
    }

    //* POSITIONS
    const positions = [
      {
        place: undefined,
        name: 'ZOLLVEREIN',
        pos: { x: -0.12, y: -0.01 },
        id: 1
      }, // ZOLLVEREIN
      {
        place: undefined,
        name: 'JAHRHUNDDERTHALLE',
        pos: { x: -0.078, y: -0.002 },
        id: 2
      }, // JAHRHUNDDERTHALLE
      { place: undefined, name: 'HANSA', pos: { x: -0.03, y: 0.02 }, id: 3 }, // HANSA KOKEREI
      {
        place: undefined,
        name: 'LANDSHAFTPARK',
        pos: { x: -0.18, y: 0.005 },
        id: 4
      }, // LANDSHAFTPARK
      {
        place: undefined,
        name: 'INDUSTRIEMUSEUM',
        pos: { x: -0.105, y: -0.1 },
        id: 5
      }, // INDUSTRIEMUSEUM
      // { place: undefined, name: 'EXTER', pos: { x: 0.315, y: 0.16 }, id: 6 }, // EXTER & BEGATALBAHN
      { place: undefined, name: 'ZECHE', pos: { x: -0.115, y: 0.04 }, id: 7 } // ZECHE CONSOLATION
    ]

    const lastPosition = {
      place: undefined,
      name: 'EXTER',
      pos: { x: 0.315, y: 0.16 },
      id: 6
    }

    //* LOADER
    const loader = new OBJLoader(manager)
    let iconBase

    loader.load(
      // resource URL
      'https://res.cloudinary.com/nancloud/raw/upload/v1626057879/NRW/WEB/MODELS/Spike_qzdhzh.obj',
      // called when resource is loaded
      function (object) {
        iconBase = object.children[0]
        positions.forEach((position) => {
          position.place = new Place(
            scene,
            position.pos,
            position.name,
            position.id
          )
        })
      }
    )

    //* METHODS
    const onWindowResize = () => {
      BOUNDS = root.value.getBoundingClientRect()
      WIDTH = BOUNDS.width
      HEIGHT = BOUNDS.height
      ORIENTATION =
        window.innerWidth > window.innerHeight ? 'Landscape' : 'Portrait'
      camera.aspect = WIDTH / HEIGHT
      camera.updateProjectionMatrix()
      renderer.setSize(WIDTH, HEIGHT)
      cameraPos()
    }

    function onMouseMove (event) {
      // calculate mouse position in normalized device coordinates
      // (-1 to +1) for both components
      // console.log(event)
      if (window.innerWidth > 1024) {
        mouse.value.x = event.clientX
        mouse.value.y = event.clientY
        mouseMap.x = (event.clientX / window.innerWidth) * 2 - 1
        mouseMap.y = -(event.clientY / window.innerHeight) * 2 + 1
      } else {
        const touch = event.changedTouches[0]
        mouse.value.x = touch.clientX
        mouse.value.y = touch.clientY
        mouseMap.x = (touch.clientX / window.innerWidth) * 2 - 1
        mouseMap.y = -(touch.clientY / window.innerHeight) * 2 + 1
        onMouseDown()
      }

      const movementFactor = cameraInZoom ? 10 : 6

      if (!cameraInZoom && window.innerWidth > 1024) {
        gsap.to(camera.position, {
          x: mouseMap.x / movementFactor,
          y: mouseMap.y / movementFactor,
          duration: 1.5,
          ease: Power4.easeOut,
          onUpdate: () => {
            camera.lookAt(meshPoint.position)
          }
        })
      }
    }

    function onMouseDown () {
      if (INTERSECTED !== null) {
        if (window.innerWidth > 1024) {
          content.value.open(INTERSECTED.name)
          INTERSECTED.place.activated()
          activatedPlaces++
          if (activatedPlaces === 3) {
            setLast()
          }
          cameraZoom(true, INTERSECTED)
        } else if (mouseMap.y < (ORIENTATION === 'Landscape' ? -0.4 : -0.65)) {
          content.value.open(INTERSECTED.name)
          INTERSECTED.place.activated()
          activatedPlaces++
          if (activatedPlaces === 3) {
            setLast()
          }
          cameraZoom(true, INTERSECTED)
        }
      }
    }

    const setLast = () => {
      setTimeout(() => {
        lastPosition.place = new Place(
          scene,
          lastPosition.pos,
          lastPosition.name,
          lastPosition.id
        )
        positions.push(lastPosition)
      }, 500)
    }

    const init = () => {
      BOUNDS = root.value.getBoundingClientRect()
      WIDTH = BOUNDS.width
      HEIGHT = BOUNDS.height
      ORIENTATION =
        window.innerWidth > window.innerHeight ? 'Landscape' : 'Portrait'
      renderer.setSize(WIDTH, HEIGHT)

      root.value.appendChild(renderer.domElement)
      camera.aspect = WIDTH / HEIGHT
      camera.updateProjectionMatrix()
      cameraPos()
    }

    const cameraPos = () => {
      if (window.innerWidth <= 768 && ORIENTATION === 'Portrait') {
        if (window.innerHeight <= window.innerWidth * 1.5) {
          posIniCamera = 1.7
        } else {
          posIniCamera = 2.2
        }
      } else if (window.innerWidth <= 1024 && ORIENTATION === 'Landscape') {
        posIniCamera = 1.1
      } else if (window.innerWidth <= 1024 && ORIENTATION === 'Portrait') {
        posIniCamera = 1.6
      } else {
        posIniCamera = 1.2
      }
      camera.position.set(0, 0, posIniCamera)
    }

    const cameraZoom = (inOut, elem) => {
      if (inOut) {
        cameraInZoom = true
        const desfase = WIDTH / HEIGHT / 9.6
        gsap.to(meshPoint.position, {
          x: elem.pos.x + desfase,
          y: elem.pos.y,
          z: 0.06,
          duration: 1.5
        })
        gsap.to(camera.position, {
          x: elem.pos.x + desfase,
          y: elem.pos.y - 0.2,
          z: 0.3,
          duration: 1.5,
          onUpdate: () => {
            camera.lookAt(meshPoint.position)
          },
          onComplete: () => {
            cancelAnimationFrame(reqMap)
          }
        })
      } else {
        reqMap = requestAnimationFrame(animate)
        gsap.to(meshPoint.position, {
          x: 0,
          y: 0,
          z: 0,
          duration: 1
        })
        gsap.to(camera.position, {
          x: 0,
          y: 0,
          z: posIniCamera,
          duration: 1,
          onUpdate: () => {
            camera.lookAt(meshPoint.position)
          },
          onComplete: () => {
            cameraInZoom = false
          }
        })
      }
    }

    const animations = () => {
      // materialSky.uniforms.u_time.value += 0.001
      colorMap.needsUpdate = true

      // update the picking ray with the camera and mouse position
      raycaster.setFromCamera(mouseMap, camera)
      // calculate objects intersecting the picking ray
      const intersects = raycaster.intersectObjects(scene.children)

      if (intersects.length > 0) {
        if (intersects[0].object.name !== '' && !cameraInZoom) {
          const newIntersected = positions.filter((obj) => {
            return obj.name === intersects[0].object.name
          })[0]
          if (INTERSECTED != null && INTERSECTED !== newIntersected) {
            INTERSECTED.place.close()
          }
          INTERSECTED = newIntersected
          INTERSECTED.place.open()
        } else {
          if (INTERSECTED != null) {
            INTERSECTED.place.close()
          }
          INTERSECTED = null
        }
      }
    }

    const animate = () => {
      // stats.begin()
      animations()
      // stats.end()
      reqMap = requestAnimationFrame(animate)
      renderer.render(scene, camera)
    }

    //* LIFECYCLE HOOKS
    onBeforeMount(() => {
      window.addEventListener('resize', onWindowResize, false)
    })

    onMounted(() => {
      init()
      animate()
      window.history.pushState({}, '', 'Home')
    })

    //* EVENT LISTENER
    if (window.innerWidth > 1024) {
      window.addEventListener('mousemove', onMouseMove, false)
      window.addEventListener('mousedown', onMouseDown, false)
    } else {
      // window.addEventListener('touchmove', onMouseMove, false)
      window.addEventListener('touchstart', onMouseMove, false)
    }

    return {
      root,
      label,
      content,
      setLast,
      init,
      cameraZoom,
      animate,
      onWindowResize,
      mouse
    }
  }
}
</script>

<style lang="scss" scoped>
#map {
  position: fixed;
  width: 100vw;
  height: 100%;
}
.map__label {
  position: fixed;
  text-align: right;

  width: 100vw;
  top: 40px;
  h1 {
    font-family: $lora;
    font-weight: 500;
    font-size: 17px;
    margin: 0;
    padding-right: 50px;
    color: $primary;

    span {
      font-weight: 700;
    }
  }
  p {
    font-family: $fira;
    font-size: 15px;
    margin: 0;
    padding-right: 50px;
  }

  @include breakpoint(m) {
    top: 95px;
    text-align: center;

    h1,
    p {
      padding-right: 0;
    }
  }

  @include breakpoint(o-h) {
    top: 40px;
    text-align: right;

    h1,
    p {
      padding-right: 50px;
    }
  }
}
</style>
