<template>
  <div class="content__overlay" ref="overlay" @click="close"></div>
  <div class="content" ref="content">
    <Galery
      v-if="id == 1"
      :content="activeContent"
      :title="activeTitle"
      :subtitle="activeSubtitle"
      @loaded="loaded"
    />
    <Video
      v-if="id == 2"
      :url="activeContent"
      :title="activeTitle"
      :subtitle="activeSubtitle"
      @loaded="loaded"
    />
    <Slide
      v-if="id == 3"
      :content="activeContent"
      :title="activeTitle"
      :subtitle="activeSubtitle"
      @loaded="loaded"
    />
    <div class="content__close" @click="close">
      <!-- <img
        src="https://res.cloudinary.com/nancloud/image/upload/v1624898261/NRW/WEB/ICONS/CANCEL_arvfvq.svg"
        alt="close"
        width="20"
      /> -->
      <div class="content__close-wrapper">
        <Spinner v-if="loading"/>
        <div class="content__close-line" v-if="!loading"></div>
        <div class="content__close-line" v-if="!loading"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Galery from './Galery.vue'
import Video from './Video.vue'
import Slide from './Slide.vue'
import Spinner from './Spinner.vue'
import gsap from 'gsap'

import { ref, onMounted } from 'vue'
export default {
  components: {
    Galery,
    Video,
    Slide,
    Spinner
  },
  emits: ['closeContent'],
  setup (props, context) {
    const id = ref(0)
    const label = ref('')
    const content = ref(null)
    const overlay = ref(null)
    let isAnim = false
    const overlayWidth = '0vw'
    const activeContent = ref([])
    const activeTitle = ref('')
    const activeSubtitle = ref('')

    const loading = ref(true)

    onMounted(() => {
      window.onpopstate = function (event) {
        close()
      }
    })

    const open = (text) => {
      if (isAnim) return
      loading.value = true
      switch (text) {
        case 'ZOLLVEREIN':
          id.value = 1
          window.history.pushState({}, '', 'Zeche-Zollverein')
          activeContent.value = {
            imgs: [
              'https://res.cloudinary.com/loretolala/image/upload/v1629595396/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-10_ftjxil.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595404/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-11_go8kfi.png',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595397/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-12_kfjcg8.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595397/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-13_gmf4px.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595398/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-14_snwj6k.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595398/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-15_m3j3oa.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595399/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-16_p7cmf9.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595398/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-17_ijkuhw.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595398/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-18_ew1g6d.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595397/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-19_sbyjn5.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595399/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-20_c86adx.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595398/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-21_tulafp.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735071/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-22_o0bz5h.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735071/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-23_ua6btp.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735070/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-24_usvdyk.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735070/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-25_dwbs1k.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735070/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-26_cisin1.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735071/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-27_ypciy9.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735071/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-28_n0fovr.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735071/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-29_jgxdfq.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735072/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-30_iuu6bq.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735072/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-31_sayrd2.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735071/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-32_dngz7q.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735072/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-33_fn7qgr.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735072/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-34_ovjexm.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735072/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-35_uowwe0.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735073/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-36_stbo16.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735072/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-37_ije0qo.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735072/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-38_b6fk3i.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735073/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-39_wqqa7r.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735073/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-40_mtmeen.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735073/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-41_amtrk0.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735073/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-42_yezxwf.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735074/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-43_hsntdg.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735073/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-44_mjtior.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735074/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-45_yy78lm.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735073/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-46_q0bd6d.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735074/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-47_y4yizy.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735074/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-48_vif6s4.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735074/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-49_y3cmjp.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735074/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-50_d86xfh.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735075/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-51_ahc6so.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629735074/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-52_kcgaxj.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595405/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-53_mf8cce.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595405/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-54_asi2us.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595405/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-55_eqqkch.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595405/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-56_o6khbn.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595405/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-57_uxjci1.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595395/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-132_urcdyr.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595405/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-58_ymdnz9.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595405/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-59_hpxx6a.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595406/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-60_bgw4le.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595406/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-61_oqiiyt.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595406/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-62_iqgp3x.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595406/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-63_p3iuhm.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595406/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-64_rdveqw.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595406/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-65_pmxcuc.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595408/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-66_kwcidl.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595407/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-67_ourjq8.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595407/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-68_rw5xy3.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595407/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-69_wjso7x.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595407/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-70_dq4wwb.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595408/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-71_cjorml.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595408/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-72_azzldc.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595408/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-73_upqdy8.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595408/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-74_rd625t.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595408/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-75_lvb0qn.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595409/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-76_n4ramx.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595409/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-77_kim7jr.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595409/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-78_ig6z7m.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595409/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-79_ooheih.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595409/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-80_ubxwlr.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595410/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-81_jchpwg.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595410/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-82_pfpfcm.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595410/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-83_iompln.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595410/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-84_a0rx3l.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595410/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-85_ggucuk.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595411/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-86_tisq6n.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595411/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-87_ugsyez.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595411/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-88_mr5rdl.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595411/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-89_un30wj.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595411/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-90_tkgqd9.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595411/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-91_nra276.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595411/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-92_q1mv0j.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595412/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-93_rtqx27.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595412/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-94_a7nvx1.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595412/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-95_ma9oah.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595413/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-96_k6f92m.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595412/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-97_tlwmcy.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595413/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-98_pywobc.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595413/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-99_rdhpee.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595413/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-100_gvvtik.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595414/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-101_iuqlhh.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595414/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-102_nwbgrb.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595413/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-103_flxdat.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595414/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-104_e2uzfv.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595414/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-105_cntjq3.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595414/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-106_l43ini.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595414/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-107_b2y5io.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595414/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-108_ave9ls.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595414/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-109_i5b3gz.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595415/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-110_wn8e2i.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595415/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-111_l6eu4l.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595415/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-112_migkgg.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595416/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-113_flpmwe.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595415/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-114_w8uznu.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595415/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-115_gqimbq.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595415/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-116_vlatej.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595415/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-117_zz3xup.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595416/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-118_bzv0jc.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595416/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-119_trhwjt.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595416/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-120_rwhxbo.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629595416/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-121_z03hlx.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629746268/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-122_coqvr8.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629746268/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-123_l7qcsa.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629746268/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-124_tx7qta.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629746268/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-125_yhkxlu.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629746268/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-126_v5fejp.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629746269/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-127_u2kpn2.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629746269/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-128_jl3imz.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629746269/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-129_apizhu.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629746269/WEB/SECTIONS/ZECHE%20ZOLLVEREIN/Zollverein-130_fu5zse.jpg'
            ],
            template: 0
          }
          activeTitle.value = 'ZECHE ZOLLVEREIN'
          activeSubtitle.value = 'Essen'
          break
        case 'JAHRHUNDDERTHALLE':
          id.value = 2
          window.history.pushState({}, '', 'Jahrhunderthalle')
          activeContent.value =
            'https://player.vimeo.com/video/588551844?autoplay=1?title=0&byline=0&portrait=0'
          activeTitle.value = 'JAHRHUNDERTHALLE'
          activeSubtitle.value = 'Bochum'
          break
        case 'HANSA':
          id.value = 3
          window.history.pushState({}, '', 'Kokerei-Hansa')
          activeContent.value = {
            gifs: [
              'https://res.cloudinary.com/loretolala/image/upload/v1629594848/WEB/SECTIONS/KOKEREI%20HANSA/01_tvmqyn.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629594849/WEB/SECTIONS/KOKEREI%20HANSA/02_hznshd.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629594854/WEB/SECTIONS/KOKEREI%20HANSA/03_nnf5dk.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629594857/WEB/SECTIONS/KOKEREI%20HANSA/04_zxqn2a.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629594854/WEB/SECTIONS/KOKEREI%20HANSA/05_e8mn7d.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629680665/WEB/SECTIONS/KOKEREI%20HANSA/06_l168dt.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629594862/WEB/SECTIONS/KOKEREI%20HANSA/07_f8omta.gif'
            ],
            audio:
              'https://res.cloudinary.com/loretolala/video/upload/v1629603213/WEB/SECTIONS/KOKEREI%20HANSA/HANZA_tphhhv.mp3'
          }
          activeTitle.value = 'KOKEREI HANSA'
          activeSubtitle.value = 'Dortmund'
          break
        case 'LANDSHAFTPARK':
          id.value = 2
          window.history.pushState({}, '', 'Landschaftspark')
          activeContent.value =
            'https://player.vimeo.com/video/588557090?autoplay=1?title=0&byline=0&portrait=0'
          activeTitle.value = 'LANDSCHAFTSPARK'
          activeSubtitle.value = 'Duisburg'
          break
        case 'INDUSTRIEMUSEUM':
          id.value = 1
          window.history.pushState({}, '', 'Industriemuseum')
          activeContent.value = {
            imgs: [
              'https://res.cloudinary.com/loretolala/image/upload/v1629598333/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-8_wmtbqo.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598334/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-9_iuogcr.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598332/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-10_cv9eoi.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598334/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-11_rim9rj.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598334/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-12_jladlw.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598335/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-13_kwfzkj.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598335/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-15_xl8vuo.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598335/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-16_qjs1ks.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598336/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-17_taknul.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598336/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-18_jhwwnf.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598339/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-19_qem7zo.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598335/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-20_nrlogm.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598335/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-21_dsw4eq.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598336/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-22_fmj4gj.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598336/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-23_mjqzzy.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598336/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-24_hfwcgn.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598337/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-25_adzaln.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598337/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-26_xw8poy.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598338/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-27_sx2ijj.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598338/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-28_inyfsa.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598337/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-29_god2xy.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598338/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-30_gcysj3.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598338/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-31_aigxhb.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598338/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-32_s9li8k.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598338/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-33_pmdrfk.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598339/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-34_cl9dbt.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598339/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-35_bzjbnw.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598341/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-36_cg0cn0.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598339/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-37_je2ilt.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598339/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-38_rmdh6q.jpg',
              'https://res.cloudinary.com/loretolala/image/upload/v1629598341/WEB/SECTIONS/INDUSTRIEMUSEUM/ZollvereinA-39_mbhl5r.jpg'
            ],
            template: 1
          }
          activeTitle.value = 'INDUSTRIEMUSEUM'
          activeSubtitle.value = 'Solingen'
          break
        case 'EXTER':
          id.value = 3
          window.history.pushState({}, '', 'Exter-&-Begatalbahn')
          activeContent.value = {
            gifs: [
              'https://res.cloudinary.com/loretolala/image/upload/v1629680872/WEB/SECTIONS/EXTER%20-%20BEGATALBAHN/GIF01_jcx759.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629680871/WEB/SECTIONS/EXTER%20-%20BEGATALBAHN/GIF02_hd7dau.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629680873/WEB/SECTIONS/EXTER%20-%20BEGATALBAHN/GIF03_x0a8jt.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629746515/WEB/SECTIONS/EXTER%20-%20BEGATALBAHN/GIF04_rbekb8.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629680868/WEB/SECTIONS/EXTER%20-%20BEGATALBAHN/GIF05_cbjoee.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629680887/WEB/SECTIONS/EXTER%20-%20BEGATALBAHN/GIF06_rmy6bb.gif'
            ],
            audio:
              'https://res.cloudinary.com/loretolala/video/upload/v1629603420/WEB/SECTIONS/EXTER%20-%20BEGATALBAHN/EXTER_jnr6o1.mp3'
          }
          activeTitle.value = 'EXTER & BEGATALBAHN'
          activeSubtitle.value = 'barntrup'
          break
        case 'ZECHE':
          id.value = 3
          window.history.pushState({}, '', 'Zeche-Consolidation')
          activeContent.value = {
            gifs: [
              'https://res.cloudinary.com/loretolala/image/upload/v1629653538/WEB/SECTIONS/ZECHE%20CONSOLIDATION/GIF01_hxropj.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629653538/WEB/SECTIONS/ZECHE%20CONSOLIDATION/GIF02_rv16l6.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629653542/WEB/SECTIONS/ZECHE%20CONSOLIDATION/GIF03_w78p4k.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629653542/WEB/SECTIONS/ZECHE%20CONSOLIDATION/GIF04_mg7xls.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629653542/WEB/SECTIONS/ZECHE%20CONSOLIDATION/GIF05_e7qwl8.gif',
              'https://res.cloudinary.com/loretolala/image/upload/v1629653542/WEB/SECTIONS/ZECHE%20CONSOLIDATION/GIF06_gihhod.gif'
            ],
            audio:
              'https://res.cloudinary.com/loretolala/video/upload/v1629654090/WEB/SECTIONS/ZECHE%20CONSOLIDATION/ZECHE_wbvapl.mp3'
          }
          activeTitle.value = 'ZECHE CONSOLIDATION'
          activeSubtitle.value = 'Gelsenkirchen'
          break
        default:
          id.value = 0
          window.history.pushState({}, '', '')
          break
      }
      label.value = text
      isAnim = true
      gsap.to(content.value, {
        left: overlayWidth,
        duration: 1.5,
        onComplete: () => {
          overlay.value.style.width = overlayWidth
          isAnim = false
        }
      })
    }

    const close = () => {
      if (isAnim) return
      isAnim = true
      gsap.killTweensOf(content.value)
      gsap.to(content.value, {
        left: '100vw',
        duration: 1,
        onStart: () => {
          context.emit('closeContent')
        },
        onComplete: () => {
          overlay.value.style.width = '0'
          isAnim = false
          id.value = 0
          window.history.pushState({}, '', 'Home')
        }
      })
    }

    const loaded = () => {
      loading.value = false
    }

    return {
      id,
      label,
      content,
      overlay,
      open,
      close,
      activeContent,
      activeTitle,
      activeSubtitle,
      loading,
      loaded
    }
  }
}
</script>

<style lang="scss" scoped>
.content__overlay {
  position: fixed;
  height: 100vh;
  width: 0;
  top: 0;
  left: 0;
  // background-color: rgba($dark, 0.3);
}
.content {
  position: fixed;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 100vw;
  background-color: $secundary;
  overflow: hidden;

  .content__close {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 46px;
    height: 46px;
    border: 1px solid $dark;

    cursor: pointer;

    .content__close-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      .content__close-line {
        position: absolute;
        width: 35%;
        height: 1px;
        background: $dark;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
        transition: all 0.5s;

        &:first-child {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:last-child {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    &:hover {
      .content__close-wrapper {
        .content__close-line {
          width: 50%;
        }
      }
    }
  }
}
</style>
